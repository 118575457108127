@tailwind base;
@tailwind components;
@tailwind utilities;

#daintegration {
  animation: fade 1s ease-in 3s;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
